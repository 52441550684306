import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import CTASimple from '../../components/CTASimple/CTASimple';
import theme from '../../styles/theme';
import Accordion from '../../components/Accordion/Accordion';
import LogoGrid from '../../components/LogoGrid/LogoGrid';
import Posts from '../../components/Posts/Posts';
import Content from '../../components/Content/Content';
import HeroVideo from '../../components/HeroVideo/HeroVideo';
import CTACard from '../../components/CTACard/CTACard';
import Testimonials from '../../components/Testimonials/Testimonials';
import BlurbsThree from '../../components/BlurbsThree/BlurbsThree';
import HomeIcon from '../../components/Shared/Icons/HomeLarge';
import ClipboardIcon from '../../components/Shared/Icons/Clipboard';
import PieChartIcon from '../../components/Shared/Icons/PieChartLarge';
import Loans from '../../components/Loans/Loans';
import { getPageContent } from '../../services/dpc';
import Error from '../../components/Error/Error';
import { slugByLabel } from '../../util/faqCategories';
import useGTM from '../../hooks/useGTM';
import Link from '../../components/Shared/Link';
import HeroSection from '../../components/HeroSection';
import Disclaimer from '../../components/Disclaimer/Disclaimer';
import ReactMarkdown from 'react-markdown';
import Styles, { DisclaimerLinkStyles } from './Home.styled';
import { customLink } from '../../plugins/remark/renderers';
import HeroBanner from '../../components/Contentful/HeroBanner/HeroBanner';
import createGTMClass from '../../util/createGTMClass';
import Banner from '../../components/Contentful/Banner/Banner';
import { getHexValuesInString } from '../../util/colors';
import NeonSvg from '../../components/lib/NeonSvg';
import { neonAnimations } from '../../components/lib/NeonSvg/NeonSvg';
import Picture from '../../components/Shared/Picture';
import { loadModel } from 'vmfcom-lib/utils';
import { getDPC, getBlogPosts } from '../../services/contentful';

const faqGTMLabels = {
  'how-we-calculate-your-payment-and-when-payments-are-due': 'payment_faq',
  'what-is-simple-interest': 'interest_faq',
  'requesting-loan-payoff-quote': 'payoff_quote_faq',
  'is-there-a-penalty-for-early-loan-payoff': 'early_payoff_faq',
};

const Home = ({ data, blogData, error, isLoading }) => {
  if (error) {
    console.error(error);
    return (
      <Layout>
        <Error />
      </Layout>
    );
  }

  const entry = loadModel(data, 'VMFCom', 'DynamicPageContent');

  if (isLoading) return null;

  const [triggerGTMEvent] = useGTM();
  const faqs =
    entry?.faqPod &&
    entry?.faqPod.map(
      ({
        faqQuestion: title,
        faqSummaryAnswer: answer,
        slug,
        faqCategory,
        ...rest
      }) => ({
        title,
        answer,
        showReadMore:
          rest.faqAnswerContent1 ||
          rest.faqAnswerContent2 ||
          rest.faqAnswerVideos,
        slug: slug.replace(
          'resources/faqs/',
          `resources/faqs/${slugByLabel(faqCategory?.[0])}/`
        ),
        onExpand: triggerGTMEvent({
          name: 'vmf.cta_click',
          location: 'Home Page',
          category: 'frequently_asked',
          action: 'summary',
          label:
            faqGTMLabels[
              slug.replace('/resources/faqs/', '').replace(/\/$/, '')
            ] ||
            slug
              .replace('/resources/faqs/', '')
              .replace(/-/g, '_')
              .replace(/\/$/, ''),
        }),
        onReadMore: triggerGTMEvent({
          name: 'vmf.cta_click',
          location: 'Home Page',
          category: 'frequently_asked',
          action: 'text',
          label:
            faqGTMLabels[
              slug.replace('/resources/faqs/', '').replace(/\/$/, '')
            ] ||
            slug
              .replace('/resources/faqs/', '')
              .replace('-', '_')
              .replace(/\/$/, ''),
        }),
      })
    );

  const posts =
    blogData &&
    blogData.map(({ title, contentTag, category, slug, featuredMedia }) => ({
      title,
      category,
      contentTag,
      thumbnail: `${
        featuredMedia?.media?.file?.url || featuredMedia?.thumbnail?.file?.url
      }?w=300`,
      mediaType: featuredMedia?.mediaType,
      slug,
    }));

  const testimonials =
    entry?.customerReviewPod &&
    entry.customerReviewPod.map(
      ({
        ratingStars: rating,
        customerName: author,
        reviewDate: date,
        review,
      }) => ({
        rating,
        author,
        date,
        review,
      })
    );

  return (
    <Layout isLoading={isLoading}>
      <SEO title={entry?.titleTag} description={entry?.metaDescription} />
      <Styles>
        {entry?.heroSection?.heroBanner && (
          <Banner
            imageAnchorPoint={entry?.heroSection?.heroBanner?.imageAnchorPoint}
            borderColor={
              getHexValuesInString(
                entry?.heroSection?.heroBanner?.bannerBorderColor
              )[0]
            }
            link={entry?.heroSection?.heroBanner?.bannerLink}
            title={entry?.heroSection?.heroBanner?.bannerTitle}
            image={{
              src: entry?.heroSection?.heroBanner?.desktopBannerImage?.media
                ?.file?.url,
              alt: entry?.heroSection?.heroBanner?.desktopBannerImage
                ?.description,
              sources: [
                {
                  media: `(min-width: ${theme.breakpoints.desktop})`,
                  params: '?w=626',
                  srcSet:
                    entry?.heroSection?.heroBanner?.desktopBannerImage?.media
                      ?.file?.url,
                },
                {
                  media: `(min-width: ${theme.breakpoints.tablet})`,
                  params: '?w=512',
                  srcSet:
                    entry?.heroSection?.heroBanner?.tabletBannerImage?.media
                      ?.file?.url,
                },
                {
                  media: `(max-width: ${theme.breakpoints.tablet})`,
                  params: '?w=300',
                  srcSet:
                    entry?.heroSection?.heroBanner?.mobileBannerImage?.media
                      ?.file?.url,
                },
              ],
            }}
            fadeImageToBackground={false}
            backgroundColor={
              getHexValuesInString(
                entry?.heroSection?.heroBanner?.titleBackgroundAndTextColors
              )[0]
            }
            textColor={
              getHexValuesInString(
                entry?.heroSection?.heroBanner?.titleBackgroundAndTextColors
              )[1]
            }
            className='home-banner'
          />
        )}
        <HeroVideo
          title={entry?.heroSection?.heroContent?.sectionHeader}
          link={{
            to: entry?.heroSection?.heroLinks?.[0]?.link,
            title: entry?.heroSection?.heroLinks?.[0]?.linkText,
            isExternal: true,
            newTab: true,
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_application',
              action: 'button',
              label: 'apply_1',
            }),
            className: createGTMClass('apply', 'hero', 'button'),
          }}
          secondaryLink={{
            to: entry?.heroSection?.heroLinks?.[1]?.link,
            title: entry?.heroSection?.heroLinks?.[1]?.linkText,
            isExternal: true,
            newTab: true,
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'account_sign_in',
              action: 'button',
              label: 'sign_in',
            }),
            className: createGTMClass('sign_in', 'hero', 'button'),
          }}
          thumbnail={{
            src: entry?.heroSection?.heroContent?.video?.thumbnail?.file?.url,
            alt: entry?.heroSection?.heroContent?.video?.thumbnail?.description,
          }}
          video={{
            source: entry?.heroSection?.heroContent?.video?.videoSource,
            content: entry?.heroSection?.heroContent?.video?.embeddedContent,
            playButtonStyle: 'centerCircle',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_education',
              action: 'video',
              label: 'watch_video_2',
            }),
          }}
          cta={
            <p>
              New user?
              <Link
                to={entry?.heroSection?.heroLinks?.[2]?.link}
                color={theme.colors.white}
                isExternal={true}
                newTab={true}
                onClick={triggerGTMEvent({
                  name: 'vmf.cta_click',
                  location: 'Home Page',
                  category: 'create_account',
                  action: 'text',
                  label: 'create_account',
                })}
                className={createGTMClass('create_account', 'hero', 'text')}
              >
                Create an account
              </Link>
            </p>
          }
        >
          <p>{entry?.heroSection?.heroContent?.sectionIntro}</p>
        </HeroVideo>

        <Content align='center'>
          <p>
            We offer a variety of manufactured and mobile home finance programs
            for those with perfect credit, less than perfect credit and
            first-time homebuyers*. Most importantly, our team will be here with
            you every step of the way. You’ll Feel Right at Home with Vanderbilt
            Mortgage and Finance, Inc.
          </p>
          <p className='type-extra-small'>
            *All loans are subject to credit approval
          </p>
        </Content>

        <BlurbsThree
          video={{
            source: entry?.contentVideos?.[0]?.videoSource,
            content: entry?.contentVideos?.[0]?.embeddedContent,
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_education',
              action: 'video',
              label: 'watch_video_2',
            }),
          }}
          title='Here’s a 3 step look at how it all works.'
          columnOne={
            <>
              <HomeIcon />
              <p>
                To apply for a loan to finance the purchase of your dream home,
                fill out our easy online application, and we’ll connect you with
                a licensed loan specialist.
              </p>
            </>
          }
          columnTwo={
            <>
              <ClipboardIcon />
              <p>
                A licensed loan specialist will review your application and
                contact you to obtain more information in order to provide you
                with a loan decision.
              </p>
            </>
          }
          columnThree={
            <>
              <PieChartIcon />
              <p>
                We will walk you through the process of collecting any
                documentation we may need from you in order to process your loan
                application as well as explain the credit requirements that
                apply for your selected loan program.
              </p>
            </>
          }
        />

        <Loans
          mobileTopPadding='1rem'
          tabletTopPadding='1rem'
          desktopTopPadding='1rem'
          link={{
            to: '/payment-options/',
            title: 'Learn about our payment options',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_evaluation',
              action: 'text',
              label: 'payment_options',
            }),
          }}
          viewAllLink={{
            to: '/home-loans/',
            title: 'View all',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_evaluation',
              action: 'button',
              label: 'mortgage_loan',
            }),
          }}
        />

        <LogoGrid />

        <CTACard
          backgroundTop={theme.colors.grey6}
          backgroundBottom={theme.colors.vmfBlue}
          foreground={theme.colors.white}
          link={{
            to: entry?.contentCTAs?.[0]?.firstCTAButtonUrl || '/home-buying/',
            title: entry?.contentCTAs?.[0].firstCTAButtonText || 'Learn more',
            outline: true,
            appearance: 'dark',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'home_decision',
              action: 'button',
              label: 'pre_owned',
            }),
          }}
          secondaryLink={{
            to:
              entry?.contentCTAs?.[0]?.secondCTAButtonUrl ||
              '/applynow/welcome/',
            title: entry?.contentCTAs?.[0].secondCTAButtonText || 'Apply Now',
            appearance: 'dark',
            newTab: true,
            isExternal: true,
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_application',
              action: 'button',
              label: 'apply_2',
            }),
            className: createGTMClass('apply', 'cta', 'button'),
          }}
          condensed={true}
          divider={false}
          flat={false}
        >
          <h3 className='type-h4 no-margin-bottom'>
            {entry?.contentCTAs?.[0]?.ctaHeader ||
              'Looking to Buy a Pre-owned Home?'}
          </h3>
          <p>
            {entry?.contentCTAs?.[0]?.ctaIntro ||
              'We also offer Direct Lending loans for owner-to-owner transactions and financing for pre-owned homes purchased from VMF Homes.'}
          </p>
        </CTACard>

        <CTASimple
          title={
            entry?.contentCTAs?.[1]?.ctaHeader || 'Mortgage Payment Calculator'
          }
          link={{
            title:
              entry?.contentCTAs?.[1]?.firstCTAButtonText || 'Estimate Payment',
            to:
              entry?.contentCTAs?.[1]?.firstCTAButtonUrl ||
              '/mortgage-calculator/',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_evaluation',
              action: 'button',
              label: 'estimate_payment',
            }),
          }}
          condensed={true}
        >
          <p>
            {entry?.contentCTAs?.[1]?.ctaIntro ||
              'Trying to plan what your potential payment may look like? Are you renting and want to compare your current rent to an estimated mortgage payment? Vanderbilt wants to give you the tools to plan ahead by providing our Monthly Payment Calculator.'}
          </p>
        </CTASimple>

        <Testimonials testimonials={testimonials} />

        <Accordion
          items={faqs}
          link={{
            url: '/resources/faqs/',
            label: 'View all',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'frequently_asked',
              action: 'text',
              label: 'all_faqs',
            }),
          }}
        />

        <Posts
          title='Tips & Tools'
          link={{
            title: 'View all',
            to: '/resources/blog/',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_education',
              action: 'text',
              label: 'tips_tools',
            }),
          }}
          posts={posts}
        />
        <CTASimple
          title={entry?.preFooterCta?.ctaHeader || 'Dreaming of Homeownership?'}
          link={{
            to: entry?.preFooterCta?.firstCTAButtonUrl || '/applynow/welcome/',
            title: entry?.preFooterCta?.firstCTAButtonText || 'Apply Now',
            isExternal: true,
            newTab: true,
            size: 'large',
            onClick: triggerGTMEvent({
              name: 'vmf.cta_click',
              location: 'Home Page',
              category: 'loan_application',
              action: 'button',
              label: 'apply_3',
            }),
            className: createGTMClass('apply', 'prefoot_cta', 'button'),
          }}
          background={theme.colors.vmfBlue}
        ></CTASimple>

        <DisclaimerLinkStyles>
          <Disclaimer>
            <ReactMarkdown
              className='link-styles'
              source={entry?.disclaimer}
              renderers={{
                link: customLink(
                  { target: '_blank' },
                  'http://www.nmlsconsumeraccess.org/'
                ),
              }}
            />
          </Disclaimer>
        </DisclaimerLinkStyles>
      </Styles>
    </Layout>
  );
};

Home.getInitialProps = async ({}) => {
  if (typeof window === 'undefined') {
    try {
      const dpcData = await getDPC('/');
      const blogData = await getBlogPosts(4);
      return { data: dpcData, blogData };
    } catch (err) {
      return {
        err,
      };
    }
  } else {
    window.location.reload();
  }
};

Home.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.shape({}),
  error: PropTypes.object,
};

export default Home;
